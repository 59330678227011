import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useSession } from 'src/sdk/session'
import 'src/styles/pages/cambios-devoluciones.scss'

interface Props {
  data: TAllCmsInstitutional
  location: {
    pathname: string
  }
}

interface TAllCmsInstitutional {
  allCmsInstitutionalPage: TEdges
  site: {
    siteMetadata: SiteSiteMetadata
  }
}

type TEdges = {
  edges: Array<{ node: { sections: Array<{ data: { iframe: string } }> } }>
}

const Page = ({ location, data: { site, allCmsInstitutionalPage } }: Props) => {
  const { locale } = useSession()
  const title = site?.siteMetadata?.title ?? ''
  const sections = allCmsInstitutionalPage?.edges[0]?.node?.sections

  if (!sections.length) {
    return null
  }

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <div style={{ paddingTop: '16px' }} />

      <section className="content">
        <div className="content__header">
          <h2 className="content__text-primary">Cambios y devoluciones</h2>
        </div>
        <div>
          <div className="content__container">
            <div>
              <p className="content__text-secondary">
                En TaTa queremos que estés satisfecho con tu compra, por eso
                podés solicitar a nuestro equipo de Atención al Cliente de
                nuestros locales el cambio o devolución de un producto adquirido
                en nuestro sitio. A continuación describimos nuestra Política de
                cambios y devoluciones, con plazos y condiciones para que puedas
                gestionar tu caso.
              </p>
            </div>
            <div>
              <h2 className="content__text-dark">
                Motivos válidos para cambios y devoluciones
              </h2>
              <p className="content__text-secondary">
                Los motivos por los cuales podés gestionar el cambio o la
                devolución de un producto son los siguientes:
              </p>
              <ul className="content__list">
                <li className="content__list__list-text">
                  Producto dañado o vencido
                </li>
                <li className="content__list__list-text">
                  El tamaño no es correcto
                </li>
                <li className="content__list__list-text">
                  Me equivoqué en la compra
                </li>
                <li className="content__list__list-text">
                  El producto no era lo que yo esperaba
                </li>
                <li className="content__list__list-text">
                  Recibí algo que no pedí (o un producto diferente)
                </li>
              </ul>
            </div>
            <div>
              <h2 className="content__text-dark">
                ¿Cómo gestionar un cambio o una devolución?
              </h2>
              <p className="content__text-secondary">
                Para gestionar un cambio o devolución parcial, siempre tendrás
                que acercarte personalmente a Atención al Cliente del Local más
                cercano. Para que la devolución sea efectiva, deberás contar con
                el producto y el ticket de compra. Al momento de realizar el
                cambio o devolución, podrá ser en efectivo o con transferencia
                bancaria. Si la devolución es por la totalidad del pedido y tu
                pago fue por mercadopago, la verás efectiva a los 30 días. La
                devolución sólo será efectiva siempre y cuando sea en el plazo
                de 7 días hábiles de recibido el producto.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query LandingPageCambiosQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage(
      filter: {
        seo: { siteMetadataWithSlug: { slug: { eq: "/garantia200" } } }
      }
    ) {
      edges {
        node {
          seo {
            siteMetadataWithSlug {
              slug
            }
          }
          name
          sections {
            data
          }
        }
      }
    }
  }
`

export default Page

export { Head } from 'src/components/common/Head'
